import {
    USER_STATUS_ACTIVE, USER_STATUS_DISCONTINUE,
    USER_TYPE_ADMIN, USER_TYPE_LICENCE_ADMIN, USER_TYPE_LICENCE_OWNER,
    USER_TYPE_MANAGER,
    USER_TYPE_PRACTITIONER,
    USER_TYPE_RECEIPTS, USER_TYPE_SUPERADMIN
} from "../constants/userConstants"
export default {

    usersTypes() {
        return [
            {
                id: USER_TYPE_PRACTITIONER,
                name: 'Practitioner'
            },
            {
                id: USER_TYPE_RECEIPTS,
                name: 'Receptionist'
            },
            {
                id: USER_TYPE_MANAGER,
                name: 'Manager'
            },
            {
                id: USER_TYPE_ADMIN,
                name: 'Office Admin'
            },
            {
                id: USER_TYPE_LICENCE_OWNER,
                name: 'Licence Owner'
            },
            {
                id: USER_TYPE_LICENCE_ADMIN,
                name: 'Licence Admin'
            },
            {
                id: USER_TYPE_SUPERADMIN,
                name: 'Super Admin'
            }
        ]
    },
    usersTypesFilters() {
        const temps = this.usersTypes()
        let types = [
            {
                id: 0,
                name: 'All'
            },
            ...temps
        ]
        types = _.reject(types, { id: USER_TYPE_ADMIN })
        types = _.reject(types, { id: USER_TYPE_SUPERADMIN })
        return types
    },
    getUserTypeName(searchTypeId) {
        if (searchTypeId) {
            const types = this.usersTypes()
            const typeData = types.find(({id}) => id === searchTypeId)
            if (typeData) {
                return typeData.name
            }
        }
    },

    getUserTypeIdByName(searchTypeName) {
        if (searchTypeName) {
            const types = this.usersTypes()
            const typeData = types.find(({name}) => name === searchTypeName)
            if (typeData) {
                return typeData.id
            }
        }
    },
    getGenderOptions() {
        return [
            {
                id: 1,
                name: 'Male'
            },
            {
                id: 2,
                name: 'Female'
            }
        ]
    },
    getUserGenderStr(gender) {
        let genderStr = ''
        switch (gender) {
            case 0:
                genderStr = ''
                break
            case 1:
                genderStr = 'Male'
                break
            case 2:
                genderStr = 'Female'
                break
        }
        return genderStr
    },
    getFullName(user) {
        if (user) {
            let fullName = ''
            if (user.username) {
                fullName = user.username
            } else {
                fullName = user.firstName
            }
            if (user.lastName) {
                return fullName + ' ' + user.lastName
            } else {
                return fullName
            }
        }
    },
    getLegalName(user) {
        if (user) {
            return user.firstName + ' ' + user.lastName
        }
    },
    statuses() {
        return [
            {
                id: 1,
                name: 'Active'
            },
            {
                id: 2,
                name: 'Discontinue'
            },
            {
                id: 3,
                name: 'Delete'
            }
        ]
    },
    getUserStatusList() {
        return [
            {
                label: 'Active',
                value: USER_STATUS_ACTIVE
            },

            {
                label: 'Discontinue',
                value: USER_STATUS_DISCONTINUE
            },
            {
                label: 'All',
                value: 0
            }
        ]
    }
}