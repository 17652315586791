import ModuleNavAclApi from "../api/ModuleNavAclApi"
import {
    MODULE_ROLE_ALLOW_ALL,
    MODULE_ROLE_ALLOW_OVERRIDE,
    PROGRAM_SETTING_ALLOW_ALL,
    PROGRAM_SETTING_ALLOW_OVERRIDE,
    PROGRAM_SETTING_DISABLE,
    USER_TYPE_MANAGER,
    USER_TYPE_RECEIPTS
} from "../constants/userConstants"
import userHelp from "./userHelp"

export default {
    getModuleOptionLabel(status, roleId) {
        let str = ''
        const roleStr = userHelp.getUserTypeName(roleId)
        switch (status) {
            case PROGRAM_SETTING_ALLOW_ALL:
                str = 'Enable All'
                break
            case PROGRAM_SETTING_DISABLE:
                str = 'Disable'
                break
            case PROGRAM_SETTING_ALLOW_OVERRIDE:
                str = 'Allow Override'
                break
        }
        return str + ' ' + roleStr
    },
    
    hasModuleRole(sessionMember) {
        if (_.includes([USER_TYPE_RECEIPTS, USER_TYPE_MANAGER], sessionMember?.typeId)) {
            return true
        } else{
            return false
        }
    },

    async getMemberAclList() {
        const navsResults = await ModuleNavAclApi.getSessionMemberNavs()
        const navs = []
        _.forEach(navsResults, (nav) => {
            if (nav?.ModuleNav?.navIds) {
                const navIds = JSON.parse(nav?.ModuleNav?.navIds)
                _.forEach(navIds, (navId) => {
                    const data = {
                        status: nav.status,
                        navId: navId
                    }
                    navs.push(data)
                })
            }
        })
        return navs
    },

    checkMemberPathAcl(sessionUser, path, memberAcls) {
        console.log(memberAcls)
        let allow = false
        if (_.includes([USER_TYPE_RECEIPTS, USER_TYPE_MANAGER], sessionUser?.typeId)) {
            if (memberAcls) {
                _.forEach(memberAcls, memberAcl => {
                    if (memberAcl.navId === path) {
                        if (memberAcl.status === MODULE_ROLE_ALLOW_ALL) {
                            allow = true
                        } else if (memberAcl.status === MODULE_ROLE_ALLOW_OVERRIDE) {
                            allow = true
                        }
                    }
                })
            }
        }
        return allow
    }
}